import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { format } from "date-fns";

const ArchivedDocuments = () => {
  const { t, i18n } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const itemsPerPage = 10;

  // Fetch documents dynamically based on the current page and search term
  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}api/archived-documents`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              page: currentPage,
              limit: itemsPerPage,
              search: searchTerm,
            },
          }
        );

        const { documents, totalPages } = response.data;
        setDocuments(documents);
        setTotalPages(totalPages);
        setMessage(null);
      } catch (error) {
        setMessage(t("archived_documents.error_fetching_documents"));
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [currentPage, searchTerm, t]);

  // Pagination controls
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md w-[98%]">
      <div className="flex justify-between items-center mb-8">
        {/* Title */}
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
          {t("archived_documents.title")}
        </h1>

        {/* Search Field */}
        <div className="relative w-80 min-w-[220px]">
          <img
            src="/search_input.svg"
            alt="search icon"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
          />
          <input
            type="text"
            placeholder={t("archived_documents.search_placeholder")}
            className="pl-10 w-full rounded-lg px-4 py-2 dark:bg-gray-700 dark:text-gray-200 bg-gray-100 text-gray-600 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Document Table */}
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <p className="text-gray-800 dark:text-gray-200 bg-gray-100 dark:bg-gray-900 text-sm px-4 py-2 rounded-lg shadow-md animate-pulse">
            {t("archived_documents.loading")}...
          </p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white dark:bg-gray-700 table-auto border-collapse">
            <thead>
              <tr className="text-gray-800 dark:text-gray-200 bg-gray-100 dark:bg-gray-900 text-sm">
                <th className="px-4 py-2">{t("archived_documents.type")}</th>
                <th className="px-4 py-2">{t("archived_documents.vendorCode")}</th>
                <th className="px-4 py-2">{t("archived_documents.vendorName")}</th>
                <th className="px-4 py-2">{t("archived_documents.da")}</th>
                <th className="px-4 py-2">{t("archived_documents.customer")}</th>
                <th className="px-4 py-2">{t("archived_documents.invoiceId")}</th>
                <th className="px-4 py-2">{t("archived_documents.invoiceTotal")}</th>
                <th className="px-4 py-2">{t("archived_documents.processingDate")}</th>
                <th className="px-4 py-2">{t("archived_documents.createdBy")}</th>
                <th className="px-4 py-2">{t("archived_documents.sentToClientAt")}</th>
                <th className="px-4 py-2">{t("archived_documents.status")}</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc, index) => (
                <tr
                  key={index}
                  className="text-gray-600 text-sm dark:text-gray-200 border-b dark:border-gray-600 h-[75px]"
                >
                  <td className="px-4 py-2">{doc.docType || "--"}</td>
                  <td className="px-4 py-2">{doc.vendorSAPCode || "--"}</td>
                  <td className="px-4 py-2">{doc.vendorName || "--"}</td>
                  <td className="px-4 py-2">{doc.trigram || "--"}</td>
                  <td className="px-4 py-2">{doc.customerName || "--"}</td>
                  <td className="px-4 py-2 text-center">{doc.invoiceId || "--"}</td>
                  <td className="px-4 py-2 text-right">
                    {new Intl.NumberFormat(i18n.language, {
                      style: "currency",
                      currency: doc.currency || "EUR",
                    }).format(doc.invoiceTotal || 0)}
                  </td>
                  <td className="px-4 py-2 text-center">
                    {doc.invoiceDate
                      ? format(new Date(doc.invoiceDate), "dd/MM/yyyy")
                      : "--"}
                  </td>
                  <td className="px-4 py-2">{doc.createdBy || "--"}</td>
                  <td className="px-4 py-2 text-center">
                    {doc.sentToClientAt
                      ? format(new Date(doc.sentToClientAt), "dd/MM/yyyy")
                      : "--"}
                  </td>
                  <td className="px-4 py-2">
                    <span
                      className={`px-6 py-0.5 rounded-full text-xs font-semibold ${getStatusColor(
                        doc.docStatus
                      )}`}
                    >
                      {doc.docStatus
                        ? t(
                            `archived_documents.status_${doc.docStatus.toLowerCase()}`
                          )
                        : "--"}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="flex justify-between items-center mt-6">
        <div className="text-sm text-gray-600 dark:text-gray-400">
          {t("archived_documents.showing")} {currentPage}{" "}
          {t("archived_documents.of")} {totalPages}{" "}
          {t("archived_documents.pages")}
        </div>

        <div className="flex items-center space-x-2">
          {/* First Page */}
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
            className={`px-3 py-2 rounded ${
              currentPage === 1
                ? "text-gray-400 dark:text-gray-500 cursor-not-allowed"
                : "text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
            }`}
          >
            &laquo;
          </button>

          {/* Previous Page */}
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-2 rounded ${
              currentPage === 1
                ? "text-gray-400 dark:text-gray-500 cursor-not-allowed"
                : "text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
            }`}
          >
            &lt;
          </button>

          {/* Current Page */}
          <span className="px-3 py-2 text-indigo-500 font-bold bg-gray-200 dark:bg-gray-700">
            {currentPage}
          </span>

          {/* Next Page */}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-2 rounded ${
              currentPage === totalPages
                ? "text-gray-400 dark:text-gray-500 cursor-not-allowed"
                : "text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
            }`}
          >
            &gt;
          </button>

          {/* Last Page */}
          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
            className={`px-3 py-2 rounded ${
              currentPage === totalPages
                ? "text-gray-400 dark:text-gray-500 cursor-not-allowed"
                : "text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
            }`}
          >
            &raquo;
          </button>
        </div>
      </div>

      {message && (
        <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg z-50">
          {message}
        </div>
      )}
    </div>
  );
};

// Helper function to determine status badge color
const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case "sent":
      return "text-green-600 bg-green-200";
    case "inprocessing":
      return "text-yellow-600 bg-yellow-200";
    case "failed":
      return "text-red-600 bg-red-200";
    case "cancelled":
      return "text-gray-600 bg-gray-200";
    default:
      return "text-gray-600 bg-gray-200";
  }
};

export default ArchivedDocuments;
